<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" />
  <div class="main_right">
    <div class="search" v-if="searchOptions.items && searchOptions.items.length">
      <vxe-form v-bind="searchOptions" @submit="searchEvent" @reset="searchResetEvent"></vxe-form>
    </div>
    <div class="wrapper">
      <div class="table">
        <vxe-grid ref="xGrid" height="auto" v-bind="gridOptions" :loading="loading" :data="tableData"></vxe-grid>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          export: true,
          refresh: {
            query: () => {
              this.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: 'configName',
            title: "<div class=date>项目名称</div>",
            type: "html",
            width: 150,
          },
          {
            field: 'total',
            title: '总收费',
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: 'refundAmount',
            title: '退款金额',
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: 'cashAmount',
            title: '现金',
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: 'smAmount',
            title: '扫码枪支付',
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: 'aliOfflineAmount',
            title: '支付宝(线下支付)',
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: 'wxOfflineAmount',
            title: '微信(线下支付)',
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
        printConfig: {
          sheetName: `月报表${this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd")}`,
          isColgroup: true,
          isFooter: true,
          original: false,
          style: `
          * {
              font-family: "宋体";
          }
          .title {
            height: 60px;
            line-height: 60px;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
          }
          .date {
            width : 80px;
            text-align: center;
          }
          `,
          beforePrintMethod: ({ content }) => {
            // 拦截打印之前，返回自定义的 html 内容
            let { data } = this.searchOptions;
            const topHtml = `<h1 class="title">月报表（${data.beginDate} 至 ${data.endDate}）</h1>`;
            return topHtml + content;
          },
        },
        showFooter: true,
        footerMethod: ({ columns, data }) => {
          return [
            columns.map((column, columnIndex) => {
              if (columnIndex === 0) {
                return '合计'
              } else if (columnIndex === 1) {
                return "合计"
              } else {
                return this.sumNum(data, column.property)
              }
            }),
          ]
        },
      },
      tableData: [],
      formOptions: {
        // 新增功能字段配置
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "dateType",
            title: "时间周期",
            itemRender: {
              name: "$select",
              optionTypeCode: "dateType",
              props: {
                type: "date",
                placeholder: "请选择开始时间",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == "month") {
                    let month = this.$XEUtils.toDateString(new Date(), "yyyy-MM");
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(month, 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(month, 0, "last"), "yyyy-MM-dd 23:59:59");
                  } else if (data[property] == "quarter") {
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data["beginDate"], 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data["beginDate"], 2, "last"), "yyyy-MM-dd 23:59:59");
                  } else if (data[property] == "year") {
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "last"), "yyyy-MM-dd 23:59:59");
                  } else if (data[property] == "custom") {
                    data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "first"), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "last"), "yyyy-MM-dd 23:59:59");
                  } else {
                    data["beginDate"] = this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd 00:00:00");
                    data["endDate"] = this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd 23:59:59");
                  }
                  this.getLists();
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "日期",
            visibleMethod({ data }) {
              if (data.dateType == "date") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                labelFormat: "yyyy-MM-dd",
                placeholder: "请选择日期",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "月份",
            visibleMethod({ data }) {
              if (data.dateType == "month") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "month",
                labelFormat: "yyyy-MM",
                placeholder: "请选择季度",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data[property], 0, "first"), "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data[property], 0, "last"), "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "季度",
            visibleMethod({ data }) {
              if (data.dateType == "quarter") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "quarter",
                labelFormat: "yyyy年 第q季度",
                placeholder: "请选择季度",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data[property], 0, "first"), "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(data["beginDate"], 2, "last"), "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "年",
            visibleMethod({ data }) {
              if (data.dateType == "year") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "year",
                labelFormat: "yyyy年",
                placeholder: "请选择年",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data[property], 0, "first"), "yyyy-MM-dd 00:00:00");
                  data["endDate"] = this.$XEUtils.toDateString(this.$XEUtils.getWhatYear(data["beginDate"], 0, "last"), "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            field: "beginDate",
            title: "开始时间",
            visibleMethod({ data }) {
              if (data.dateType == "custom") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                labelFormat: "yyyy-MM-dd",
                placeholder: "请选择开始时间",
              },
              events: {
                change: ({ data, property }) => {
                  data["beginDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 00:00:00");
                }
              }
            },
          },
          {
            field: "endDate",
            title: "结束时间",
            visibleMethod({ data }) {
              if (data.dateType == "custom") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                labelFormat: "yyyy-MM-dd",
                placeholder: "请选择结束时间",
              },
              events: {
                change: ({ data, property }) => {
                  data["endDate"] = this.$XEUtils.toDateString(data[property], "yyyy-MM-dd 23:59:59");
                }
              }
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          dateType: "month",
          beginDate: this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(new Date(), 0, "first"), "yyyy-MM-dd 00:00:00"),
          endDate: this.$XEUtils.toDateString(this.$XEUtils.getWhatMonth(new Date(), 0, "last"), "yyyy-MM-dd 23:59:59"),
          // beginDate: this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd 00:00:00"),
          // endDate: this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd 23:59:59"),
        },
      },
    };
  },
  created() {
    this.getSysDictEvent();
  },
  methods: {
    ...mapActions(["getSysDictService", 'getStatisticsService']),
    handleNodeClick(treeData, node) {
      if (node.type == 3 && node.children.length) {
        this.currNode = node.children[0];
        this.searchOptions.data["communityId"] = this.currNode.id;
        this.getLists();
      }
      if (node.type == "1001") {
        this.currNode = node;
        this.searchOptions.data["communityId"] = this.currNode.id;
        this.getLists();
      }
    },

    // 搜索功能
    searchEvent() {
      this.getLists();
    },

    // 搜索重置功能
    searchResetEvent() {
      this.getLists();
    },

    // 获取数据字典
    getSysDictEvent() {
      this.getSysDictService({
          params: ["dateType"]
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.searchOptions.items = [...this.$Tools.buildDataByDicts(this.searchOptions.items, data, { value: "code", label: "name" })];
          }
        });
    },

    // 获取费用项
    getLists() {
      this.getStatisticsService({
        service: `/statistics/orderReport/getMonthIncomeByFeeType`,
        params: this.searchOptions.data || {},
      }).then(({ code, data, otherData }) => {
        if (code == 200) {
          this.tableData = data;
          this.gridOptions.mergeFooterItems = [
            { row: 0, col: 0, rowspan: 1, colspan: 2 }
          ]
        }
      });
    },

    // 动态计算表尾的合计
    sumNum(list, field) {
      let count = 0;
      list.forEach(item => {
        count = this.$XEUtils.add(count, item[field]) // 30 Number(item[field])
      })
      return this.$XEUtils.commafy(count, { digits: 2 })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
